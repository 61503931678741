import React from 'react';
import '../App.css'; 
import fabrica from '../assets/FABRICA-2.jpg'
import mochila from '../assets/mochila.jpg'
import controlacc from '../assets/control-de-acceso-con-tarjeta-electronica-1024x576.jpg'
import seguridad from '../assets/seguridad-remota-1-1024x576.jpg'
import biometria from '../assets/biometrico.jpg'
import auto from '../assets/auto.jpg'
import barrio from '../assets/BARRIO-1.jpg'

import { Container, Row, Col, Image } from 'react-bootstrap';

const Servicios = () => {
  
  const IconoFlecha = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className="icono-flecha"
    >
      <path d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"></path>
    </svg>
  );


  return (
    <div>
        <div className="header-section-servicios">
            <div className="overlay">
                <h1 className="header-title">SERVICIOS</h1>
            </div>
        </div>
        <Container className="my-5">
      <h2 className="text-center mb-5">Nuestro trabajo</h2>
      <p className="text-center">
        Nos especializamos en soluciones globales de seguridad privada, priorizando siempre a nuestros clientes.
        Buscamos superar sus expectativas con servicios excepcionales y relaciones comerciales sólidas y duraderas, 
        aspirando a la excelencia en nuestro trabajo.
      </p>


      <Row className="align-items-center pt-5 pb-5">
      <Col md={5} className="image-col">
        <div className="image-container">
          <Image 
            src={fabrica}
            alt="Seguridad Corporativa 1"
            className="img-fluid"
          />
          <Image 
            src={mochila}
            alt="Seguridad Corporativa 2"
            className="img-fluid"
          />
        </div>
      </Col>
      <Col md={7}>
        <h3>Seguridad Física Corporativa</h3>
        <p>
          Ofrecemos servicio a corporaciones como centros comerciales, fábricas, laboratorios, etc. Nuestro servicio 
          integral de seguridad permite que el cliente esté informado en tiempo real del estado de la operación en ejecución.
        </p>
        <ul>
          <li><IconoFlecha /> Controles de acceso de personal, visitas y proveedores.</li>
          <li><IconoFlecha /> Control de vehículos e instalaciones.</li>
          <li><IconoFlecha /> Monitoreo del perímetro 24hs.</li>
        </ul>
      </Col>
    </Row>




      <hr className="divider2" />
      <Row className="align-items-center pt-5 pb-5">
      <Col md={5}>
          <h3>Seguridad Física Residencial</h3>
          <p>
          Soluciones de seguridad a la medida de cada barrio cerrado, condominio o edificio. Somos especialistas en vigilancia y protección de bienes.
          </p>
          <ul>
            <li><IconoFlecha />  Personal capacitado y equipado con elementos necesarios para cada objetivo.</li>
            <li><IconoFlecha />  Móviles de recorrido.</li>
            <li><IconoFlecha />  Monitoreo del perímetro.</li>
            <li><IconoFlecha />  Control de vehículos e instalaciones.</li>
          </ul>
        </Col>

        <Col md={5} className="image-col">
        <div className="image-container">
          <Image 
            src={auto}
            alt="Seguridad Corporativa 1"
            className="img-fluid"
          />
          <Image 
            src={barrio}
            alt="Seguridad Corporativa 2"
            className="img-fluid"
          />
        </div>
      </Col>
        
      </Row>
      <hr className="divider2" />
      <Row className="align-items-center pt-5 pb-5">
        <Col md={5}>
          <Image
            src={controlacc}
            alt="Dispositivos de Seguridad Electrónica"
            className="img-fluid mb-3 large-image"
          />
          <Row>
            <Col>
              <Image
                src={seguridad}
                alt="CCTV"
                className="img-fluid  small-image"
              />
            </Col>
            <Col>
              <Image
                src={biometria}
                alt="Acceso Biométrico"
                className="img-fluid  small-image"
              />
            </Col>
          </Row>
        </Col>
        <Col md={5} className='px-5'>
          <h3>Dispositivos de seguridad electrónica</h3>
          <p>
            Contamos con servicios complementarios que potencian la seguridad.
          </p>
          <ul className="security-list">
            <li><IconoFlecha />  Sistemas de CCTV</li>
            <li><IconoFlecha />  Sistemas de acceso eléctrico</li>
            <li><IconoFlecha />  Sistemas de control de acceso biométrico.</li>
          </ul>
        </Col>
      </Row>

    </Container>
       

     </div>

  );
};

export default Servicios;
