import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Request from './Request';
import ModalRequest from './ModalRequest'
import '../App.css'; 
import monitoreo3 from '../assets/monitoreo-3.jpg'
import industrias from '../assets/industrias.jpg'
import barrio from '../assets/barrio-cerrado.jpg'
import comercio from '../assets/service-02.jpg'
import trato from '../assets/trato.jpg'
import servicios from '../assets/servicios.jpg'



const Inicio = () => {
  const IconoFlecha = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className="icono-flecha"
      >
      <path d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"></path>
    </svg>
  );

  const Escudo = () => (
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className='escudo'>
      <path d="M256-.0078C260.7-.0081 265.2 1.008 269.4 2.913L457.7 82.79C479.7 92.12 496.2 113.8 496 139.1C495.5 239.2 454.7 420.7 282.4 503.2C265.7 511.1 246.3 511.1 229.6 503.2C57.25 420.7 16.49 239.2 15.1 139.1C15.87 113.8 32.32 92.12 54.3 82.79L242.7 2.913C246.8 1.008 251.4-.0081 256-.0078V-.0078zM256 444.8C393.1 378 431.1 230.1 432 141.4L256 66.77L256 444.8z"></path></svg>
  );

  const tarjetas = [
    { src: industrias, title: 'VIGILANCIA EN INDUSTRIAS' },
    { src: comercio, title: 'VIGILANCIA EN COMERCIOS' },
    { src: barrio, title: 'VIGILANCIA EN BARRIOS CERRADOS' },
  ]; 
 

  return (
    <div>

      <Container fluid className="align-items-center justify-content-center security-section">
        <Row className="align-items-center m-5">
          <Col  md={6} lg={7} xs={12} sm={12} className="text-content px-5 ">
            <h1>Cuando se trata de fiabilidad, somos lo que necesitás!</h1>
            <p>ARMAT 03 – SERVICIOS DE SEGURIDAD PRIVADA</p>
            <ModalRequest  />
          </Col>
          <Col  md={6} lg={4} xs={12} sm={12} className="form-content ">
            <h2 className='text-white'>CONTRATÁ SEGURIDAD <br></br>PROFESIONAL</h2>
            <Request/>
          </Col>
        </Row>
      </Container>
       
      <Container fluid className="nosotros-section" >
        <Row className="justify-content-center text-center py-5" style={{ backgroundColor: '#1B2A2F', color: '#FFFFFF' }}>
          <Col md={6}>
            <h2 className="section-title">NOSOTROS</h2>
            <p className='text-white'>
              En ARMAT O3 prestamos servicios para la protección de personas y bienes en todos los entornos. Nuestra amplia experiencia, funcionamiento seguro y respuesta integral están garantizados por un equipo de profesionales comprometidos con la innovación y la calidad a todos los niveles.
            </p>
          </Col>        
        </Row>
      
      <Container>
        <hr className='text-center divider-inicio'/> 
      </Container>

        <Row className="align-items-center justify-content-center mb-5 py-5"> 
          <Col lg={6} md={5}  xs={12} sm={12} className="d-flex justify-content-center">
            <img src={monitoreo3} alt="Monitoreo" className="img-fluid" style={{ maxHeight: '400px', objectFit: 'cover' }} />
          </Col>
          <Col lg={5} md={6} xs={12} sm={12} className='mt-3'>
            <h2 className='text-white pb-3'>Aspiramos a la excelencia en nuestro trabajo</h2>
            <ul className='pb-2'> 
              <li className='text-white items-aspiramos'><IconoFlecha/> Soluciones a la medida de cada cliente</li>
              <li className='text-white items-aspiramos'><IconoFlecha/>Supervisión remota y presencial</li>
              <li className='text-white items-aspiramos'><IconoFlecha/>Prestamos servicios inmediatamente</li>
            </ul>
            <ModalRequest/>
          </Col>
        </Row>

        <Container className="pb-5" >
          <Row className="g-4 justify-content-center">
            {tarjetas.map((card, index) => (
              <Col key={index} md={4} className="d-flex justify-content-center">
                <Card className="text-white " style={{ maxWidth: '350px', minHeight: '250px' }}>
                  <Card.Img src={card.src} alt={card.title} className="" />
                  <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center">
                    <Card.Title className="mb-3">{card.title}</Card.Title>
                    <ModalRequest />
                  </Card.ImgOverlay>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>   
        
        
        <Row className="align-items-center justify-content-center p-5" style={{ backgroundColor: 'white' }}>
  <Col md={6} xs={12} className="text-black left-text mb-4 mb-md-0">
    <h2>POR QUÉ ELEGIRNOS</h2>
    <p>
      Nos especializamos en soluciones globales de seguridad privada, priorizando siempre a nuestros clientes. 
      Buscamos superar sus expectativas con servicios excepcionales y relaciones comerciales sólidas y duraderas.
    </p>
  </Col>

  <Col md={6} xs={12} className="d-flex flex-wrap justify-content-center">

    <Card className="text-white card-custom m-2" style={{ flex: '1 1 300px', maxWidth: '47%'}}>
      <div className='card-img2'>
        <Card.Img src={trato} alt="Soluciones" className="card-img2" />
      </div>
      <Card.ImgOverlay className="d-flex flex-column justify-content-between text-center">
        <div className="card-content">
          <Escudo/>
          <Card.Title>SOLUCIONES ACORDE A LAS NECESIDADES</Card.Title>
          <Card.Text className='card-text text-white'>
            Proponemos soluciones a la medida y brindamos acompañamiento técnico para la toma de decisiones.
          </Card.Text>
        </div>
        <ModalRequest />
      </Card.ImgOverlay>
    </Card>

    <Card className="text-white card-custom m-2" style={{ flex: '1 1 300px', maxWidth: '50%' }}>
      <div className='card-img2'>
        <Card.Img src={servicios} alt="Supervisión" className="card-img2" />
      </div>
      <Card.ImgOverlay className="d-flex flex-column justify-content-between text-center">
        <div className="card-content">
          <Escudo/>
          <Card.Title>SUPERVISIÓN DE CADA OBJETIVO</Card.Title>
          <Card.Text className='card-text text-white'>
            Nuestro servicio de seguridad integral permite que el cliente esté informado en tiempo real de la operación en ejecución.
          </Card.Text>
        </div>
        <ModalRequest />
      </Card.ImgOverlay>
    </Card>
  </Col>
</Row>


      </Container>
          
    </div>
  );
};

export default Inicio;
