import React, {useState} from 'react'; 
import { Button, Modal } from 'react-bootstrap';
import Request from './Request';

const ModalRequest = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div>
      <Button variant="success" onClick={handleShow}>
        Contáctanos
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>CONTRATÁ SEGURIDAD PROFESIONAL</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Request/>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalRequest;
