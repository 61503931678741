import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import logo from "../assets/cropped-Negro-371x176.png"
import { Link } from 'react-router-dom';


const Header = () => {
    const phoneNumber = '541150520070'; 
    const message = 'Hola ARMAT03, estoy interesado en sus servicios de seguridad. Me gustaría que me contacten!';
  
    const WhatsApp = ({phone, message }) => {
      const link = `https://api.whatsapp.com/send/?phone=${phone}&text=${message}`;
      return <Button variant="btn btn-xlg" href={link}>(011) 5052-0070</Button>;
    }
    return (       
        <div>
          
            <Navbar expand="lg" className="custom-color-nav">
                <Container fluid>
                    <Navbar.Brand> <img src={logo} alt="logo" className="img-fluid" style={{ maxWidth: '250px' }}/> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll" className='fs-3 '>
                      <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '250px' }}
                      >
                        <Nav.Link as={Link} to="/" className="text-white mx-3">Inicio</Nav.Link>
                        <Nav.Link as={Link} to="/quienes" className="text-white mx-3">Quienes Somos</Nav.Link>
                        <Nav.Link as={Link} to="/servicios" className="text-white mx-3">Servicios</Nav.Link>
                        <Nav.Link as={Link} to="/contacto" className="text-white mx-3">Contacto</Nav.Link>
                      </Nav>
                      <WhatsApp phone={phoneNumber} message={message} />
                      
                    </Navbar.Collapse>
                </Container>
            </Navbar>                
        </div>
     );
}
 
export default Header;