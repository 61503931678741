import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Inicio from './components/Inicio';
import Quienes from './components/Quienes';
import Servicios from './components/Servicios';
import Contacto from './components/Contacto';
import Request2 from './components/Request2';

function App() {
  return (
    <div>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/quienes" element={<Quienes />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
      <Request2/>
      <Footer />
    </Router>
    
    </div>
  );
}

export default App;
