import {React} from 'react';
import '../App.css'; 
import { Container, Row, Col, Tabs, Tab, Card } from 'react-bootstrap';
import polo from '../assets/TECHNOPOLO.jpg';
import blipack from '../assets/BLIPACK-300x60.png'
import aerosoles from '../assets/logo-AEROSOLES.png'
import ModalRequest from './ModalRequest';

const Quienes = () => {

  return (
    <div>
        <div className="header-section-quienes">
            <div className="overlay">
                <h1 className="header-title">QUIENES SOMOS</h1>
            </div>
        </div>
    <Container fluid className="company-info-section py-5">
      <Row className='px-5'>
        <Col md={12}>
          <div className="header-box mb-3">
            <h2 className="company-name">ARMAT 03 S.R.L.</h2>
            <p className="subtitle">SERVICIOS DE SEGURIDAD PRIVADA</p>
          </div>
          <p className="description">
            Somos una empresa con amplia experiencia que brinda soluciones en materia de seguridad a la medida de cada cliente.
            Esta tarea incluye la propuesta de soluciones acorde a sus necesidades y el acompañamiento en la toma de decisiones
            con recomendaciones técnicas y legales basadas en nuestro conocimiento profesional.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center py-5">
        <Col md={9}>
          <Card className="card-quienes shadow-lg">
            <Tabs defaultActiveKey="mision" id="vision-mision-valores-quienes" className="mb-3" fill>
              <Tab eventKey="vision" title="Visión">
                <Card.Body className="card-body-quienes">
                  <p className="card-text-quienes">
                    Aspiramos a convertirnos en una empresa referente dentro del sector de las instalaciones y los servicios de seguridad.
                  </p>
                </Card.Body>
              </Tab>
              <Tab eventKey="mision" title="Misión">
                <Card.Body className="card-body-quienes">
                  <p className="card-text-quienes">
                    Nuestra misión es diseñar Procesos, Procedimientos, Sistemas y Servicios que garanticen el normal desarrollo de las actividades de nuestros clientes. Alcanzar la eficiencia y calidad total asumiendo el compromiso con profesionalismo.
                  </p>
                </Card.Body>
              </Tab>
              <Tab eventKey="valores" title="Valores">
                <Card.Body className="card-body-quienes">
                  <p className="card-text-quienes">
                  En Armat03, nos impulsan constantemente el compromiso y el deseo de mejorar. Estamos aquí para ofrecerle una propuesta respaldada por nuestra experiencia, habilidades y capacidad. 
                  </p>
                </Card.Body>
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>

      <Row   className="align-items-center justify-content-between consulta-section">
        <Col md={6} className="text-white text-center">
          <h2>Tenés una consulta?</h2>
          <p className='text-white'>Nuestros asesores responderán cada una de sus consultas</p>
        </Col>
        <Col md={3} className="text-end">
          <ModalRequest />
        </Col>
      </Row>
    </Container>

      <Container fluid className='py-5' style={{backgroundColor: 'white'}}>
        <h1 className='d-flex justify-content-center text-black py-5' >NUESTROS CLIENTES</h1>
        <Row className="justify-content-center align-items-center">
          <Col xs={6} sm={4} md={3} className="d-flex justify-content-center mb-3">
            <img src={polo} alt="Polo" className="clientes-logo" />
          </Col>
          <Col xs={6} sm={4} md={3} className="d-flex justify-content-center mb-3">
            <img src={blipack} alt="Blipack" className="clientes-logo" />
          </Col>
          <Col xs={6} sm={4} md={3} className="d-flex justify-content-center mb-3">
            <img src={aerosoles} alt="Aerosoles" className="clientes-logo" />
          </Col>
        </Row>
      </Container>

      

    </div>
  )
};

export default Quienes;

